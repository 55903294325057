export const isBrowser = () => typeof window !== "undefined"

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  if (username === `richard` && password === `watson`) {
    return setUser({
      username: `richard`,
      name: `watson`,
      email: `richard@watson.co.uk`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  let user
  let isLoggedInValue = false
  if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
    user = JSON.parse(window.localStorage.getItem("gatsbyUser"));
    isLoggedInValue = !!user.username
  }
  return isLoggedInValue
}

export const logout = callback => {
  setUser({})
  callback()
}