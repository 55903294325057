import React from 'react'
import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
import logo from '../img/logo.png'

const Footer = class extends React.Component {
  
  render() {
    return (
      <footer
      style = {{
        padding: '5em 20px'
      }}
      className="footer"
      >
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column is-2">
                <img src={logo} alt="Richard Watson" />
              </div>
              <div className="column is-7">
                <section style={{
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // height: '100%',
                  // flexDirection: `column`
                }}>
                  <h4>Contact</h4>
                  <address>
                    Tel: +44 (0)7765 324460<br />
                    Email: <a href="mailto:info@richardwatson.co.uk">info@richardwatson.co.uk</a>
                  </address>
                  {/* <p>
                    Dr Andre Buthe Equine Clinic Ltd.<br />
                    The Mill House<br />
                    Poulton Mill<br />
                    Poulton<br />
                    Marlborough<br />
                    Wiltshire<br />
                    SN8 2LN
                  </p>
                  <p><strong>Office hours 9-12am 2-5pm</strong></p> */}
                </section>
              </div>
              <div className="column is-3">
                <div className="nav has-text-centered">
                  <Link to="/" className="navbar-item" title="Logo">
                    Home
                  </Link>
                  <Link className="navbar-item" to="/about/">
                    About
                  </Link>
                  <Link className="navbar-item" to="/tutoring/">
                    Tutoring
                  </Link>
                  <Link className="navbar-item" to="/contact/">
                    Contact
                  </Link>
                  <Link className="navbar-item" to="/insights/">
                    Insights
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
